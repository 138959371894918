<template>
    <div>
        <v-card class="rounded-lg" elevation="4">
            <v-tabs fixed-tabs background-color="green" dark>
                <v-tabs-slider color="#086318"></v-tabs-slider>
                <v-tab>DISTRIBUIÇÃO/DISPONIBILIZAÇÃO</v-tab>

                <v-tab-item>
                    <v-card-text>
                        <h3> Doador: {{ $route.query.item.name }}</h3>
                        <br>
                        <v-row>
                            <v-col class="d-flex" cols="12" sm="2">
                                <v-select 
                                :items="orgonsArray"
                                :rules="formRules"
                                color="#086318" 
                                v-model="distributionForm.orgon_id"
                                item-value="id"
                                item-text="name"  
                                dense 
                                outlined 
                                label="Orgãos">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="10" v-if="transplantNotPerfomed == true">
                                <v-text-field
                                    label="Qual o motivo do descarte?"
                                    variant="outlined"
                                    :rules="formRules" 
                                    color="#d21631"
                                    v-model="distributionForm.receptorRGCT"
                                    dense 
                                    outlined 
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-tab-item>
            </v-tabs>
        </v-card>

        <v-dialog v-model="realizedDialog" width="380px" persistent>
            <v-card>
                <v-card-title>
                    <v-icon style="padding: 5px; color: green;">mdi-comment-question</v-icon>
                    <span class="text-h5">Transplante Realizado?</span>
                </v-card-title>
                <br>
                <v-card-actions>
                    
                    <v-spacer></v-spacer>
            
                    <v-btn
                    color="blue-darken-1"
                    variant="text"
                    @click="realizedDialog = false, transplantNotPerfomed = true"
                    >
                    Não
                    </v-btn>
                    <v-btn
                    style="background-color:green; color: white;"
                    variant="text"
                    @click="realizedOrAvailable = true, realizedDialog = false"
                    >
                    Sim
                    </v-btn>
                </v-card-actions>
                </v-card>
        </v-dialog>

        <v-dialog v-model="realizedOrAvailable" width="650px" persistent>
            <v-card>
                <v-card-title>
                    <v-icon style="padding: 5px; color: green;">mdi-comment-question</v-icon>
                    <span class="text-h5">Transplante realizado na Bahia ou Disponibilizado?</span>
                </v-card-title>
                <br>
                <v-card-actions>
                    
                    <v-spacer></v-spacer>
            
                    <v-btn
                    style="background-color:#075214; color: white;"
                    variant="text"
                    @click="transplantBahia = true, realizedOrAvailable = false"
                    >
                    Realizado na Bahia
                    </v-btn>
                    <v-btn
                    style="background-color:green; color: white;"
                    variant="text"
                    @click="disponibilized = true, realizedOrAvailable = false"
                    >
                    Disponibilizado
                    </v-btn>
                </v-card-actions>
                </v-card>
        </v-dialog>

        <v-dialog v-model="transplantBahia" width="400px" persistent>
            <v-card>
                <v-card-title>
                    <v-icon style="padding: 5px; color: green;">mdi-comment-question</v-icon>
                    <span class="text-h5">Transplante na Bahia</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="12">
                                <h4>Confirmamos então que houve a realização de um transplante para o paciente informado abaixo:</h4>
                            </v-col>
                            <v-col cols="12" sm="6" md="12">
                                <v-textarea
                                    label="Informe o RGCT do Receptor:"
                                    auto-grow
                                    variant="outlined"
                                    :rules="formRules" 
                                    rows="3"
                                    color="#d21631"
                                    row-height="25"
                                    v-model="distributionForm.rgct_receiver"
                                    dense 
                                    outlined 
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
            
                    <v-btn
                        color="blue-darken-1"
                        variant="text"
                        @click="transplantBahia = false"
                        >
                    Cancelar
                    </v-btn>
                    <v-btn
                    style="background-color:green; color: white;"
                    variant="text"
                    @click="sendReceptorRgct()"
                    >
                    Enviar
                    </v-btn>
                </v-card-actions>
                </v-card>
        </v-dialog>

        <v-dialog v-model="disponibilized" width="600px" persistent>
            <v-card>
                <v-card-title>
                    <v-icon style="padding: 5px; color: green;">mdi-comment-question</v-icon>
                    <span class="text-h5">Disponibilização</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="12">
                                <h4>Para disponibilização preencha os campos abaixo:</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                    label="RGCT do Receptor:"
                                    variant="outlined"
                                    :rules="formRules" 
                                    color="#d21631"
                                    v-model="distributionForm.rgct_receiver"
                                    dense 
                                    outlined 
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-autocomplete
                                    color="#086318" 
                                    placeholder="uf" 
                                    :disabled="disableCep"
                                    v-model="distributionForm.state_id"
                                    :items="state"
                                    item-text="name" 
                                    item-value="id" 
                                    dense 
                                    outlined 
                                    label="Estado">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="12">
                                <v-textarea
                                    label="Informe o Motivo da Disponibilização:"
                                    auto-grow
                                    variant="outlined"
                                    :rules="formRules" 
                                    rows="3"
                                    color="#d21631"
                                    row-height="25"
                                    v-model="distributionForm.reason"
                                    dense 
                                    outlined 
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
            
                    <v-btn
                        color="blue-darken-1"
                        variant="text"
                        @click="disponibilized = false"
                        >
                    Cancelar
                    </v-btn>
                    <v-btn
                    style="background-color:green; color: white;"
                    variant="text"
                    @click="sendDisponibilized()"
                    >
                    Enviar
                    </v-btn>
                </v-card-actions>
                </v-card>
        </v-dialog>

        <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import axios from "@/axios";

export default {
    data() {
        return {
            tab:4,
            dialog: false,
            displayError: {
                display: false,
                message: [],
            },
            orgonsCapted: [],
            orgonsArray: [],
            distributionForm:{},
            pcrDonorDataForm:{},
            bloodSampleCollectionForm:{
                pephriferal:'',
                subclavian:'',
                femoral:'',
                intracardiac:'',
                others:'',
                sample_centrifugation:'',
            },
            obj:{
                blood_sample_collection: []
            },
            dialogDonor:false,
            realizedDialog: false,
            realizedOrAvailable: false,
            transplantBahia: false,
            disponibilized: false,
            transplantNotPerfomed: false,
            receptorRGCT: "",
            donor_id: "",
            loading: true,
            options: {},
            donor:[],
            state:[],
            data:[],
            arquivos: [],
            status_serology: [],
            openButtons: false,
            selected:"",
            message: "",
            snackbar: false,
            snackbar_error: false,
            messageError: "",
            unity: "",
            donorName: "",
            is_donorName: "",
            is_donorSex: "",
            openModal: false,
            donorButton: true,
            isCreate: true,
            formRules: [
                (v) => !!v || "Este campo é obrigatório",
            ],
        };
    },

    mounted(){
        this.getOrgons()
    },

    components:{},

    props:{},

    created() { 
        axios.get("/state").then((response) => {
            this.state = response.data.data;
        });
    },

    watch: {
        'distributionForm.orgon_id'(newOrgonId) {
            console.log("ID do órgão selecionado:", newOrgonId);
            this.realizedDialog = true;
        }
    },

    methods: {
        getOrgons(){
            console.log("afsfs", this.$route.query.item)
            if(this.$route.query.item.orgons_captured.length == 0){
                this.orgonsArray = []
                    let leftEye = new Object
                        leftEye.id = 10
                        leftEye.name = 'Córnea Esquerda'
                this.orgonsArray.push(leftEye)
                console.log("array" , leftEye)
                    let rightEye = new Object
                            rightEye.id = 11
                            rightEye.name = 'Córnea Direita'
                this.orgonsArray.push(rightEye)
                console.log("array" , rightEye)
            console.log("array" , this.orgonsArray)
            }else{
                axios.get(`/orgons_captured/${this.$route.query.item.id}`).then((response) => {
                    this.orgonsCapted = response.data.data;

                    for(let i = 0; i < this.orgonsCapted.length; i++){
                
                        let orgons = new Object
                            orgons.id = this.orgonsCapted[i].orgon_id
                            orgons.name = this.orgonsCapted[i].orgon_name

                        this.orgonsArray.push(orgons)
                    }
                });
            }
        },

        sendReceptorRgct(){
            let obj = new Object
                obj = this.distributionForm
                obj.donor_id = this.$route.query.item.id
                obj.state_id = 1
            axios.post(`/transplants_realized/create` , obj).then((response) => {
                console.log("array" , response)
                this.snackbar = true
                this.message = response.message
                this.transplantBahia = false
            });
        },

        sendDisponibilized(){
            let obj = new Object
                obj = this.distributionForm
                obj.donor_id = this.$route.query.item.id
            axios.post(`/availability/create` , obj).then((response) => {
                console.log("array" , response)
                this.snackbar = true
                this.message = response.message
                this.disponibilized = false
            });
        }
    }

};
</script>